"use client";

import { useState, useEffect } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { toast } from "react-toastify";
import { handleSignIn } from "@/lib/Auth";
import { EyeIcon, EyeOffIcon, Mail, Lock } from "lucide-react";
import { motion } from "framer-motion";
import { containerVariants } from "../components/SignUp/animations";

export default function LoginScreen() {
  const router = useRouter();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isFocusedEmail, setIsFocusedEmail] = useState(false);
  const [isFocusedPassword, setIsFocusedPassword] = useState(false);

  // Ocultar las burbujas en la página de login
  useEffect(() => {
    // Ocultar las burbujas
    const bubbles = document.querySelector('.bubbles') as HTMLElement;
    if (bubbles) {
      bubbles.style.display = 'none';
    }

    // Restaurar las burbujas cuando el componente se desmonte
    return () => {
      if (bubbles) {
        bubbles.style.display = 'block';
      }
    };
  }, []);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password: string) => {
    return (
      password.length >= 6 && /\d/.test(password) && /[a-zA-Z]/.test(password)
    );
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsEmailValid(validateEmail(e.target.value));
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setIsPasswordValid(validatePassword(e.target.value));
  };

  const handleLogin = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevenir comportamiento por defecto

    if (!email.trim() || !password.trim()) {
      toast.error("Todos los campos son obligatorios");
      return;
    }

    if (!isEmailValid || !isPasswordValid) {
      toast.error("Por favor, corrija los errores en el formulario");
      return;
    }

    setIsLoading(true);
    await handleSignIn(email, password, setIsLoading, router);
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="flex min-h-[78dvh]  md:min-h-[94dvh] bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800 relative !z-[999999] overflow-hidden">
      {/* Elementos decorativos de fondo */}
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden pointer-events-none">
        <div className="absolute -top-24 -left-24 w-96 h-96 bg-blue-400/20 dark:bg-blue-600/10 rounded-full blur-3xl"></div>
        <div className="absolute top-1/2 -right-48 w-96 h-96 bg-indigo-400/20 dark:bg-indigo-600/10 rounded-full blur-3xl"></div>
        <div className="absolute -bottom-24 left-1/3 w-64 h-64 bg-sky-400/20 dark:bg-sky-600/10 rounded-full blur-3xl"></div>
      </div>

      {/* Panel izquierdo - Imagen decorativa */}
      <div className="hidden md:flex md:w-1/2 bg-cover bg-center object-left relative overflow-hidden" style={{ backgroundImage: "url('/images/preload.png')" }}>
        {/* Overlay con gradiente */}
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900/40 to-blue-900/10"></div>

        {/* Contenido del panel izquierdo */}
        <div className=" w-full h-full flex flex-col items-start justify-center p-10 xl:p-12 z-10">
          <div className="max-w-lg space-y-4 xl:space-y-6 absolute bottom-16 xl:bottom-20 xl:left-10 ">
            <h3 className="font-black text-5xl tracking-tight drop-shadow-lg text-white">
              Bienvenido!
            </h3>
            <p className="text-md text-white/90 leading-relaxed drop-shadow-sm">
              Accede a tu cuenta para disfrutar de todos nuestros servicios y vivir la experiencia completa de Surfway.
            </p>
            <div className="flex items-center space-x-3 pt-4">
              <div className="w-12 h-1 bg-white/70 rounded-full"></div>
              <div className="w-3 h-1 bg-white/40 rounded-full"></div>
              <div className="w-2 h-1 bg-white/30 rounded-full"></div>
            </div>
          </div>
        </div>
      </div>

      {/* Panel derecho - Formulario */}
      <div className="w-full md:w-1/2 flex items-center justify-center p-6 relative">
        <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-blue-500/10 to-indigo-500/5 rounded-bl-full pointer-events-none"></div>

        <div
          className="w-full max-w-md space-y-8 md:space-y-5 3xl:space-y-8 bg-white/20 backdrop-blur-xl p-2 pb-5 px-8 3xl:p-8 rounded-2xl shadow-[0_8px_30px_rgb(0,0,0,0.12)] dark:shadow-[0_8px_30px_rgba(0,0,0,0.3)] transition-all duration-300 border border-white/20 dark:border-gray-700/30"
          style={{
            backgroundImage: "radial-gradient(circle at 100% 0%, rgba(44, 153, 255, 0.08) 0%, transparent 25%)",
            backgroundSize: "cover"
          }}
        >
          <div className="flex justify-center relative mb-6">
            <div className="absolute -top-6 left-1/2 transform -translate-x-1/2 w-24 h-24 bg-blue-400/30 dark:bg-blue-600/20 rounded-full blur-xl"></div>
          </div>

          {/* Encabezado */}
          <span className="w-full flex justify-center items-center flex-col gap-3 xl:gap-4 text-2xl xl:text-3xl font-black tracking-tight bg-gradient-to-r from-blue-600 via-indigo-600 to-blue-600 bg-clip-text text-transparent text-center">
            INICIAR SESIÓN
            <div className="w-20 h-1.5 bg-gradient-to-r from-blue-500 to-indigo-500 rounded-full mx-auto"></div>
          </span>

          <p className="text-sm md:text-md text-gray-700 leading-relaxed text-center">
            Ingrese sus datos para acceder a su cuenta
          </p>

          {/* Campos del formulario */}
          <div className="mt-8 space-y-5">
            {/* Campo de email */}
            <div className="relative group">
              <Label
                htmlFor="email"
                className={`block text-xs font-medium mb-1 transition-colors duration-200 ${!isEmailValid ? "text-red-500" : isFocusedEmail ? "text-blue-600 dark:text-blue-400" : "text-gray-700 dark:text-gray-300"
                  }`}
              >
                Correo electrónico
              </Label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className={`h-5 w-5 ${!isEmailValid ? "text-red-500" : isFocusedEmail ? "text-blue-600 dark:text-blue-400" : "text-gray-400"
                    }`} />
                </div>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  onFocus={() => setIsFocusedEmail(true)}
                  onBlur={() => setIsFocusedEmail(false)}
                  className={`w-full pl-5 h-10 xl:h-11 rounded-lg border ${!isEmailValid
                      ? "border-red-500 focus-visible:ring-red-500"
                      : isFocusedEmail
                        ? "border-blue-500 focus-visible:ring-blue-500"
                        : "border-gray-300 dark:border-gray-600"
                    } shadow-sm transition-all duration-200 group-hover:border-gray-400 dark:group-hover:border-gray-500 bg-white/90 backdrop-blur-sm`}
                  placeholder="correo@ejemplo.com"
                />
              </div>
              {!isEmailValid && (
                <p className="text-red-500 text-xs mt-1 flex items-center">
                  <span className="inline-block mr-1">•</span>
                  Correo electrónico no válido
                </p>
              )}
            </div>

            {/* Campo de contraseña */}
            <div className="relative group">
              <div className="flex items-center justify-between mb-1">
                <Label
                  htmlFor="password"
                  className={`block text-xs font-medium transition-colors duration-200 ${!isPasswordValid ? "text-red-500" : isFocusedPassword ? "text-blue-600 dark:text-blue-400" : "text-gray-700 dark:text-gray-300"
                    }`}
                >
                  Contraseña
                </Label>
                <Link
                  href="/recoveryPassword"
                  className="text-xs font-medium text-blue-600 hover:text-blue-500 dark:text-blue-400 relative z-10 hover:underline"
                >
                  ¿Olvidaste tu contraseña?
                </Link>
              </div>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className={`h-5 w-5 ${!isPasswordValid ? "text-red-500" : isFocusedPassword ? "text-blue-600 dark:text-blue-400" : "text-gray-400"
                    }`} />
                </div>
                <Input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={handlePasswordChange}
                  onFocus={() => setIsFocusedPassword(true)}
                  onBlur={() => setIsFocusedPassword(false)}
                  className={`w-full pl-5 h-10 xl:h-11 rounded-lg border ${!isPasswordValid
                      ? "border-red-500 focus-visible:ring-red-500"
                      : isFocusedPassword
                        ? "border-blue-500 focus-visible:ring-blue-500"
                        : "border-gray-300 dark:border-gray-600"
                    } shadow-sm transition-all duration-200 group-hover:border-gray-400 dark:group-hover:border-gray-500 bg-white/90 backdrop-blur-sm`}
                  placeholder="••••••••"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 z-10"
                  onClick={() => setShowPassword(!showPassword)}
                  aria-label={showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                >
                  {showPassword ? <EyeOffIcon className="h-4 w-4" /> : <EyeIcon className="h-4 w-4" />}
                </button>
              </div>
              {!isPasswordValid && (
                <p className="text-red-500 text-xs mt-1 flex items-center">
                  <span className="inline-block mr-1">•</span>
                  La contraseña debe tener al menos 6 caracteres y contener letras y números.
                </p>
              )}
            </div>

            {/* Botón de inicio de sesión */}
            <button
              type="submit"
              onClick={handleLogin}
              disabled={isLoading}
              className={`w-full flex justify-center items-center gap-2 py-3 px-4 border border-transparent rounded-lg shadow-md text-sm font-medium text-white transition-all duration-300 relative z-10 h-10 xl:h-11 mt-6 ${!email.trim() || !isEmailValid || !password.trim() || !isPasswordValid
                  ? "bg-gray-400 cursor-not-allowed opacity-70"
                  : "bg-gradient-to-r from-blue-600 via-indigo-500 to-blue-600 hover:from-blue-700 hover:via-indigo-600 hover:to-blue-700 cursor-pointer active:scale-[0.98] hover:shadow-lg"
                }`}
            >
              {isLoading ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Cargando...
                </span>
              ) : (
                "Iniciar sesión"
              )}
            </button>

            <div className="text-center mt-6">
              <p className="text-sm text-gray-600 dark:text-gray-400">
                ¿No tienes una cuenta?{" "}
                <Link href="/signUp" className="font-medium text-blue-600 hover:text-blue-500 dark:text-blue-400 relative z-10 hover:underline">
                  Regístrate
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
